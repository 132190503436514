<script setup lang="ts">
import {ProjectScreen} from '/src/api/projects';
import Icon from '/src/components/layout/Icon.vue';

const props = defineProps<{
    id: number;
    screen: ProjectScreen;
    isSubscriptionActive: boolean;
    isDuplicateEnabled: boolean;
}>();

const emit = defineEmits([
    'removeScreen',
    'copyScreen',
    'publishScreen',
    'makePrivate',
    'makePublic',
    'renameScreen',
    'showModalPricing',
]);

async function copyScreen() {
    if (props.isDuplicateEnabled) {
        emit('copyScreen');
    } else {
        emit('showModalPricing');
    }
    (document.activeElement as HTMLElement)?.blur();
}

async function removeScreen() {
    emit('removeScreen');
    (document.activeElement as HTMLElement)?.blur();
}
async function makePrivate() {
    if (props.isSubscriptionActive) {
        emit('makePrivate');
    } else {
        emit('showModalPricing');
    }
    (document.activeElement as HTMLElement)?.blur();
}

async function publishScreen() {
    emit('makePublic');
    (document.activeElement as HTMLElement)?.blur();
}

async function renameScreen() {
    emit('renameScreen');
}
</script>

<template>
    <ul class="menu menu-sm bg-neutral rounded-lg w-36 p-2">
        <li>
            <a
                class="pl-1"
                @click="copyScreen"
            >
                <Icon
                    type="clone"
                    sm
                    class="text-gray-500"
                />
                Duplicate
                <div
                    v-if="!isDuplicateEnabled"
                    class="bg-success text-secondary text-xs capitalize px-1 rounded-full absolute -top-1 -right-4"
                >
                    Plus
                </div>
            </a>
        </li>
        <!-- <li>
      <a class="pl-1" @mousedown="renameScreen">
        <Icon type="abc" sm class="text-gray-500" />
        Rename...</a
      >
    </li> -->
        <li v-if="screen.private">
            <a
                class="pl-1"
                @click="publishScreen"
            >
                <Icon
                    type="shield"
                    sm
                    class="text-gray-500"
                />
                Publish
            </a>
        </li>
        <li v-else>
            <a
                class="pl-1"
                @click="makePrivate"
            >
                <Icon
                    type="shield"
                    sm
                    class="text-gray-500"
                />
                Make Private
                <div
                    v-if="!isSubscriptionActive"
                    class="bg-success text-secondary text-xs capitalize px-1 rounded-full absolute -top-1 -right-4"
                >
                    Plus
                </div>
            </a>
        </li>
        <li>
            <a
                class="pl-1 text-error"
                @click="removeScreen"
            >
                <Icon
                    type="trash"
                    sm
                    class="text-gray-500"
                />
                Delete
            </a>
        </li>
    </ul>
</template>
