<script lang="ts" setup>
import {defineProps, ref, toRefs} from 'vue';
import {addCustomImage, useSession} from '../../core/session';
import {generateUID, loadImageAsync, logEvent, readFileAsync, readTextFileAsync, toCppVariableName} from '../../utils';
import FuiImportWizard from './FuiImportWizard.vue';
import {uploadAsset} from '/src/api/assets';

const props = defineProps<{
    title: string;
    active?: boolean;
    type?: 'image' | 'code';
    project_id?: number;
    isSandbox?: boolean;
}>();

const emit = defineEmits(['setActiveTab', 'uploading']);

const fileInput = ref(null);

const session = useSession();
const fileLoadedCounter = ref(0);
const openWizard = ref(false);
const wizardRef = ref(null);
const showWizard = ref(false);
const isLoading = ref(false);

async function onFileChange(e) {
    isLoading.value = true;
    if (props.type === 'image') {
        const files = e.target.files;
        openWizard.value = true;
        await handleImages(files);
    } else {
        const file = e.target.files[0];
        if (!file?.name) {
            return;
        }
        const fileResult = await readTextFileAsync(file);
        session.importCode(fileResult);
        emit('setActiveTab', 'code');
    }
    isLoading.value = false;
    resetFileInput();
}

function extractFileName(file) {
    return toCppVariableName(file.name.substring(0, file.name.lastIndexOf('.')) || file.name);
}

async function handleImages(files) {
    const images = [];
    for (const file of files) {
        const name = extractFileName(file);
        const fileResult = await readFileAsync(file);
        const image = await loadImageAsync(fileResult);
        images.push([name, image]);
    }
    showWizard.value = true;
    wizardRef.value.setImagesPreview(images);
}
async function saveImage(processedImagesArr) {
    if (props.isSandbox) {
        for (const [name, width, height, image] of processedImagesArr) {
            addCustomImage(name, width, height, image, false);
        }
    } else {
        const uploadPromises = processedImagesArr.map(async ([name, width, height, image]) => {
            const file = await fetch(image.src)
                .then((res) => res.blob())
                .then((blob) => new File([blob], name, {type: 'image/png'}));
            const asset = await uploadAsset(file, props.project_id, 'image');
            addCustomImage(name, width, height, image, false, asset.id);
        });

        await Promise.all(uploadPromises);
    }
    emit('setActiveTab', 'images');
    showWizard.value = false;
    openWizard.value = false;
}

function resetFileInput() {
    fileInput.value = null;
    fileLoadedCounter.value++;
}

function closeWizard() {
    openWizard.value = false;
    showWizard.value = false;
}
</script>
<template>
    <label
        class="btn btn-sm btn-outline btn-primary uppercase font-sans overflow-hidden"
        @click="logEvent(`button_import_${type}`)"
    >
        <FuiImportWizard
            v-if="openWizard && type === 'image'"
            ref="wizardRef"
            :visible="showWizard"
            @onClose="closeWizard"
            @onSave="saveImage"
        />
        <input
            type="file"
            style="position: fixed; top: -100%"
            :accept="type === 'image' ? 'image/*' : '.c,.cpp,.ino,.txt,.xbm'"
            @change="onFileChange"
            ref="fileInput"
            :key="fileLoadedCounter"
            :multiple="type === 'image'"
            :disabled="isLoading"
        />
        {{ title }}
        <div
            v-if="isLoading"
            class="loading loading-sm loading-spinner"
        ></div>
    </label>
</template>
<style lang="css"></style>
