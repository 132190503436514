<script setup lang="ts">
import {Project, ProjectScreen} from '/src/api/projects';
import {defineProps, ref} from 'vue';
import Icon from '/src/components/layout/Icon.vue';
import Button from '/src/components/layout/Button.vue';
import ProjectWizard from './ProjectWizard.vue';
import {logEvent} from '/src/utils';

const props = defineProps<{
    project: Project;
    screen?: ProjectScreen;
    full?: boolean;
    readonly?: boolean;
}>();

const emit = defineEmits(['update']);

const isWizardVisible = ref(false);

function projectWizard(e) {
    isWizardVisible.value = true;
    logEvent('button_project_settings');
}

function closeModal() {
    isWizardVisible.value = false;
}

function update() {
    emit('update');
}
</script>

<template>
    <div class="flex gap-2 items-center pb-2">
        <template v-if="project.title">
            <div class="pr-2 breadcrumbs pt-1 pb-0">
                <ul>
                    <li v-if="full && !readonly">
                        <div class="truncate max-w-64">
                            <router-link
                                class="link-primary"
                                to="/"
                            >
                                Projects
                            </router-link>
                        </div>
                    </li>
                    <li>
                        <Icon
                            v-if="!project.private"
                            type="globe"
                            sm
                            class="text-success mr-1"
                        />
                        <div class="truncate max-w-48">{{ project.title }}</div>
                    </li>
                    <li v-if="screen">
                        <div class="truncate max-w-48">{{ screen.title }}</div>
                    </li>
                </ul>
            </div>
            <div class="badge badge-sm border-gray-500 bg-secondary text-gray-400">
                {{ project.platform }}
            </div>
            <div class="badge badge-sm border-gray-500 bg-secondary text-gray-400">
                {{ project.screen_x }}×{{ project.screen_y }}
            </div>
            <div
                class="tooltip tooltip-bottom z-20"
                data-tip="Project settings"
                v-if="!readonly"
            >
                <Icon
                    type="cog"
                    lg
                    primary
                    pointer
                    @click="projectWizard"
                />
            </div>
            <!-- <div
        class="ml-2 btn btn-sm text-primary btn-outline btn-primary px-2 justify-self-end"
        v-if="full"
      >
        <Icon type="share" /> Share
      </div> -->
        </template>
    </div>
    <ProjectWizard
        v-if="isWizardVisible"
        @close="closeModal"
        @update="update"
        :project="project"
    />
</template>
