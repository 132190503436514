<script setup lang="ts">
import {computed, defineProps, ref, toRefs} from 'vue';
import {logEvent} from '/src/utils';
import {addCustomFont, useSession} from '/src/core/session';
import router from '/src/router';
import Icon from '/src/components/layout/Icon.vue';
import {removeAssetFile, uploadAsset} from '/src/api/assets';

const props = defineProps<{
    fonts: TPlatformFont[];
    fontsUsed: string[];
    disabled: boolean;
    fontValue: string;
    project_id: number;
}>();

const session = useSession();
const {state} = session;
const {platform, subscription, customFonts} = toRefs(state);

const isSubscriptionActive = computed(() => !!subscription.value && subscription.value?.status !== 'expired');

const fontTypes = computed(() => {
    if (!platform.value) return null;
    switch (platform.value) {
        case 'flipper':
            return null;
        case 'u8g2':
            return '.bdf';
        case 'uint32':
            return '.bdf, .h';
        default:
            return '.h';
    }
});

const fontsList = computed(() => {
    return [...props.fonts, ...customFonts.value];
});

const fontsMenu = ref(null);
const fontsBtn = ref(null);
const fileLoadedCounter = ref(0);
const isLoading = ref(false);
const isFontWarningVisible = ref(false);

const emit = defineEmits(['change']);

function onClick(font: TPlatformFont) {
    fontsBtn.value.blur();
    logEvent('select_font', font.name);
    emit('change', {
        target: {value: font.name},
    });
}

function hideFontsMenu() {
    fontsMenu.value.classList.add('hidden');
}
function showFontsMenu() {
    fontsMenu.value.classList.remove('hidden');
}

async function onFileChange(e: Event) {
    logEvent('import_font');
    isLoading.value = true;
    fileLoadedCounter.value += 1;
    const files = (e.target as HTMLInputElement).files;
    if (!files) return;
    const uploadPromises = Array.from(files).map(async (file: File) => {
        if (!file.name) {
            return;
        }
        const asset = await uploadAsset(file, props.project_id, 'font');
        addCustomFont(asset);
    });

    showFontsMenu();
    await Promise.all(uploadPromises);
    isLoading.value = false;
}

function deleteFont(fontToDelete) {
    customFonts.value = customFonts.value.filter((font) => font.file !== fontToDelete.file);
    removeAssetFile(fontToDelete.file);
}

function showToast() {
    isFontWarningVisible.value = true;
    setTimeout(() => {
        isFontWarningVisible.value = false;
    }, 3000);
}
</script>

<template>
    <div class="toast toast-top toast-center z-50">
        <div
            class="alert alert-warning"
            v-if="isFontWarningVisible"
        >
            <span>Cannot delete a font that is being used in the current project</span>
        </div>
    </div>
    <button
        ref="fontsBtn"
        :disabled="disabled"
        class="select select-sm select-bordered relative"
        :class="{'cursor-not-allowed': disabled}"
        @click.self="showFontsMenu"
        @blur="hideFontsMenu"
    >
        <div class="truncate pointer-events-none w-[128px] text-left flex">
            {{ fontsList.find((font) => font.name === fontValue)?.title }}
        </div>
        <div class="overflow-hidden w-48 right-[100%] -top-2 absolute z-10 rounded-box">
            <ul
                ref="fontsMenu"
                class="menu menu-xs max-h-[75vh] overflow-scroll box-content bg-secondary block hidden"
            >
                <template v-if="fontTypes">
                    <li :class="{'pointer-events-none': !isSubscriptionActive}">
                        <label class="relative">
                            Import {{ fontTypes.toUpperCase() }} font...
                            <div
                                v-if="isLoading"
                                class="loading loading-xs loading-spinner text-primary"
                            ></div>
                            <input
                                type="file"
                                style="position: fixed; top: -1000%"
                                :accept="fontTypes"
                                @change="onFileChange"
                                ref="fileInput"
                                :key="fileLoadedCounter"
                                :multiple="true"
                                :disabled="isLoading"
                            />
                            <a
                                class="bg-success text-black badge ml-2 absolute pointer-events-auto"
                                v-if="!isSubscriptionActive"
                                @click.prevent="
                                    fontsBtn.blur();
                                    router.push('/price');
                                    logEvent('button_pro', 'import_font');
                                "
                            >
                                <span class="font-bold">Plus</span>
                            </a>
                        </label>
                    </li>
                    <div class="divider h-1 py-0 my-1"></div>
                </template>
                <template v-if="customFonts.length">
                    <TransitionGroup name="highlight">
                        <li
                            v-for="font in customFonts"
                            :key="font.name"
                        >
                            <a
                                class="flex justify-between custom-font-item max-w-full"
                                :class="{active: fontValue === font.name}"
                                @click="onClick(font)"
                            >
                                <div class="truncate">{{ font.title }}</div>
                                <Icon
                                    v-if="!fontsUsed.includes(font.name)"
                                    type="trash"
                                    class="-mr-1 custom-font-icon"
                                    danger
                                    title="Delete font"
                                    xs
                                    @click.prevent.stop="deleteFont(font)"
                                />
                                <Icon
                                    v-else
                                    type="trash"
                                    class="-mr-1 custom-font-icon text-neutral"
                                    xs
                                    @click.prevent.stop="showToast"
                                />
                            </a>
                        </li>
                    </TransitionGroup>
                    <div class="divider h-1 py-0 my-1"></div>
                </template>
                <li
                    v-for="font in fonts"
                    :key="font.name"
                >
                    <a
                        :class="{active: fontValue === font.name}"
                        @click="onClick(font)"
                    >
                        {{ font.title }}
                    </a>
                </li>
            </ul>
        </div>
    </button>
</template>
<style lang="css" scoped>
.custom-font-item:hover .custom-font-icon {
    display: inline;
}

.custom-font-item.active .custom-font-icon {
    display: none;
}

.custom-font-icon {
    display: none;
}

.highlight-enter-active {
    animation: highlight 1s;
}

@keyframes highlight {
    from {
        background-color: rgba(255, 255, 255, 0.5);
    }
    to {
        background-color: rgba(255, 255, 255, 0);
    }
}
</style>
