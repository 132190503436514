<script lang="ts" setup>
import {computed, onMounted, ref, toRefs} from 'vue';
import {useSession} from '/src/core/session';
import GalleryProject from './GalleryProject.vue';
import {getGalleryEditorsChoice, getGalleryTrending, getPublicProjectsWithScreens} from '/src/api/projects';
import Button from '/src/components/layout/Button.vue';

const session = useSession();
const {state} = session;
const {auth} = toRefs(state);

const defaultCount = 30;

const projects = ref([]);
const activeTab = ref('editors');
const isLoaded = ref(false);
const from = ref(0);
const to = ref(defaultCount);
const isNextVisible = ref(true);
const isNextDisabled = ref(false);

async function nextPage() {
    from.value += defaultCount + 1;
    to.value += defaultCount + 1;
    isNextDisabled.value = true;
    await getProjects(activeTab.value);
    isNextDisabled.value = false;
}

async function getProjects(tab) {
    let result;
    switch (tab) {
        case 'editors':
            result = await getGalleryEditorsChoice(from.value, to.value);
            projects.value.push(...result);
            break;
        case 'trending':
            result = await getGalleryTrending(from.value, to.value);
            projects.value.push(...result);
            break;
        case 'all':
            result = await getPublicProjectsWithScreens(from.value, to.value);
            projects.value.push(...result);
            break;
        default:
            break;
    }
    if (result.length < defaultCount) {
        isNextVisible.value = false;
    }
}

async function setActiveTab(tabname) {
    from.value = 0;
    to.value = defaultCount;
    projects.value = [];
    isNextVisible.value = true;

    activeTab.value = tabname;
    isLoaded.value = false;
    await getProjects(activeTab.value);
    isLoaded.value = true;
}

onMounted(async () => {
    isLoaded.value = false;
    await getProjects(activeTab.value);
    isLoaded.value = true;
});
</script>
<template>
    <div class="my-4 text-center">
        <div class="text-lg font-bold">Every screen is a painting</div>
        <div class="text">Public projectes created by our users</div>
    </div>
    <div class="mb-6 flex flex-row gap-4 items-center mx-auto">
        <div
            role="tablist"
            class="tabs tabs-boxed w-fit bg-secondary font-bold"
        >
            <a
                role="tab"
                class="tab"
                :class="{'tab-active': activeTab === 'editors'}"
                @click="setActiveTab('editors')"
            >
                Highlights
            </a>
            <!-- <a
                role="tab"
                class="tab"
                :class="{'tab-active': activeTab === 'trending'}"
                @click="setActiveTab('trending')"
            >
                Trending
            </a> -->
            <a
                role="tab"
                class="tab"
                :class="{'tab-active': activeTab === 'all'}"
                @click="setActiveTab('all')"
            >
                Everything
            </a>
        </div>
        <!-- <div>
            <template v-if="activeTab === 'editors'">Handpicked by Lopaka team</template>
            <template v-if="activeTab === 'all'">All public projects</template>
        </div> -->
    </div>
    <div
        v-if="!isLoaded"
        class="mx-auto py-48 text-center"
    >
        <span class="loading loading-spinner text-primary loading-lg"></span>
    </div>
    <template v-else>
        <div class="grid md:grid-cols-3 lg:grid-cols-5 gap-6 mb-8">
            <template
                v-for="project in projects"
                :key="project.id"
            >
                <div
                    v-if="project.screens && project.screens[0].img_preview"
                    class=""
                >
                    <GalleryProject
                        :project="project"
                        :backgroundColor="session.getPlatformFeatures(project.platform).screenBgColor"
                    />
                </div>
            </template>
        </div>
        <Button
            class="mx-auto mb-8"
            @click="nextPage"
            v-if="isNextVisible"
            :disabled="isNextDisabled"
        >
            <span
                v-show="isNextDisabled"
                class="loading loading-spinner"
            ></span>
            Show more
        </Button>
    </template>
</template>
<style lang="css"></style>
