<script lang="ts" setup>
import {ref, Teleport} from 'vue';
import FuiImageEditor from './FuiImageEditor.vue';
import Button from '/src/components/layout/Button.vue';

const emit = defineEmits(['onClose', 'onSave']);

const props = defineProps<{
    visible: boolean;
}>();

const editorRef = ref(null);
const sourceImages = ref([]);
const isWizardDisabled = ref(false);
const activeImage = ref();

function setImagesPreview(images: [string, HTMLImageElement][]) {
    sourceImages.value = images;
    editorRef.value.setImage(images[0][0], images[0][1]);
    activeImage.value = images[0][0];
}

function cancel() {
    emit('onClose');
}

function selectImage(name, image) {
    editorRef.value.setImage(name, image);
    activeImage.value = name;
}

async function processImages() {
    isWizardDisabled.value = true;
    const processedImagesArr = [];
    for (const [name, image] of sourceImages.value) {
        const [imageProcessed, width, height] = await editorRef.value.process(image);
        processedImagesArr.push([name, width, height, imageProcessed]);
    }
    emit('onSave', processedImagesArr);
}

defineExpose({
    setImagesPreview,
});
</script>
<template>
    <Teleport to="body">
        <div
            class="modal font-sans text-white opacity-100 pointer-events-auto"
            v-show="visible"
        >
            <div
                v-if="isWizardDisabled"
                class="fixed top-1/2 z-50 text-center"
            >
                <div class="loading loading-spinner text-primary"></div>
                <div>Processing image(s)...</div>
            </div>
            <div
                class="modal-box mb-20 border border-primary max-w-none pointer-events-auto opacity-100"
                :class="{
                    'pointer-events-none blur-sm': isWizardDisabled,
                    'w-[1200px]': sourceImages.length > 1,
                    'w-[900px]': sourceImages.length <= 1,
                }"
            >
                <div class="flex flex-row gap-4">
                    <div
                        v-if="sourceImages.length > 1"
                        class="w-[30%]"
                    >
                        <div class="label">Images</div>
                        <div class="max-h-[500px] overflow-scroll flex flex-wrap gap-4">
                            <div
                                class="cursor-pointer border-2 border-base-300 bg-base-300 rounded p-2"
                                :class="{
                                    'border-primary': name === activeImage,
                                }"
                                v-for="[name, image] in sourceImages"
                                @click="selectImage(name, image)"
                            >
                                <img
                                    :src="image.src"
                                    :alt="name"
                                    class="object-contain max-h-16"
                                />
                            </div>
                        </div>
                    </div>
                    <FuiImageEditor
                        ref="editorRef"
                        :single="sourceImages.length <= 1"
                    />
                </div>
                <div class="col-span-2 flex flex-row justify-between mt-4">
                    <Button
                        @click="cancel"
                        danger
                    >
                        Cancel
                    </Button>
                    <Button
                        @click="processImages"
                        :success="true"
                    >
                        Import
                    </Button>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<style lang="css" scoped></style>
